@tailwind base;
@tailwind components;
@tailwind utilities;

/* get google fonts */
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@700&family=Raleway&display=swap');

@layer base {
  @font-face {
    font-family: Metropolis;
    font-weight: 400;
    src: url(./fonts/metropolis-regular.otf) format('otf');
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Raleway', sans-serif;
  color: rgba(0, 102, 162);
}

body {
  margin: 0;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 24px !important;
  color: rbga(26, 26, 26);
}

.map-container {
  top: 0;
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: -5;
}

button[type='button'].primary {
  font-weight: 500;
  background-color: rgba(0, 0, 0, 0);
  background-image: linear-gradient(
    180deg,
    rgba(252, 234, 187) 0%,
    rgba(255, 221, 0) 100%
  );
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 2px 0px;
  box-sizing: rgba(26, 26, 26);
  letter-spacing: 1px;
}

button[type='button']:hover {
  transform: scale(1.05);
}
